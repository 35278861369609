<template>
  <v-container fluid>
    <v-card class="pa-4">
      <v-card-title class="text-h3 text-center d-flex justify-center">
        Coverage Needs <br> Calculator
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="calculateCoverage"
        >
          <v-text-field
            v-for="field in fields"
            :key="field.model"
            v-model.number="$data[field.model]"
            :label="field.label"
            :prepend-inner-icon="field.icon"
            :rules="field.rules"
            outlined
          />
          <v-row>
            <v-col>
              <p
                v-if="showInsuranceNeed"
                class="text-center text-h2 font-weight-bold"
              >
                Insurance Needed Today: {{ formattedInsuranceNeededToday }}
              </p>
              <p
                v-if="userpresent && showAddtionalCoverageMsg"
                class="text-center text-h3 font-weight-bold"
              >
                {{ additionalCoverageMsg }}
              </p>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          x-large
          class="font-weight-bold mr-0"
          color="primary"
          :disabled="!valid"
          @click="calculateCoverage"
        >
          Calculate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
  import numeral from 'numeral'

  export default {
    data () {
      return {
        valid: false,
        annualIncome: null,
        percentageToReplace: null,
        age: null,
        legacyAmount: null,
        funeralExpenses: null,
        outstandingMortgage: null,
        outstandingDebt: null,
        cashInvestments: null,
        lifeInsuranceInForce: null,
        insuranceNeededToday: null,
        calculatorRules: {
          requiredIncludeZero: value => Boolean(value) || value === 0 || 'Required.',
          required: value => Boolean(value) || 'Required, must not be 0.',
          percentageRequired: value => (value >= 0 && value <= 999) || 'Required, must be between 0 and 999.',
          ageRequired: value => (value >= 18 && value <= 99) || 'Required, must be between 18 and 99.'
        }
      }
    },
    computed: {
      formattedInsuranceNeededToday () {
        return numeral(this.insuranceNeededToday).format('$0,0')
      },
      userpresent () {
        return this.$store.getters.getuser !== null
      },
      additionalCoverageMsg () {
        return `Your calculation shows what appears to be a total insurance need of ${this.formattedInsuranceNeededToday}. We can offer you $1M today through our accelerated program. An advisor will be in touch to discuss options for your additional insurance needs.`
      },
      showInsuranceNeed () {
        return (this.insuranceNeededToday && !this.userpresent) || (this.insuranceNeededToday !== null && this.insuranceNeededToday <= 1_000_000 && this.userpresent)
      },
      showAddtionalCoverageMsg () {
        return this.insuranceNeededToday > 1_000_000
      },
      fields () {
        return [
          { model: 'annualIncome', label: 'Annual Income', icon: 'mdi-currency-usd', rules: [this.rules.required] },
          { model: 'percentageToReplace', label: 'Percentage you wish to replace', icon: 'mdi-percent', rules: [this.rules.percentageRequired] },
          { model: 'age', label: 'Your Current Age', rules: [this.rules.ageRequired] },
          { model: 'legacyAmount', label: 'Legacy Amount for Dependents', icon: 'mdi-currency-usd', rules: [this.rules.requiredIncludeZero] },
          { model: 'funeralExpenses', label: 'Funeral Expenses', icon: 'mdi-currency-usd', rules: [this.rules.requiredIncludeZero] },
          { model: 'outstandingMortgage', label: 'Outstanding Mortgage Amount', icon: 'mdi-currency-usd', rules: [this.rules.requiredIncludeZero] },
          { model: 'outstandingDebt', label: 'Outstanding Debt', icon: 'mdi-currency-usd', rules: [this.rules.requiredIncludeZero] },
          { model: 'cashInvestments', label: 'Cash & Investments', icon: 'mdi-currency-usd', rules: [this.rules.requiredIncludeZero] },
          { model: 'lifeInsuranceInForce', label: 'Life Insurance in Force', icon: 'mdi-currency-usd', rules: [this.rules.requiredIncludeZero] }
        ]
      }
    },
    methods: {
      async calculateCoverage () {
        const formResult = await this.$refs.form.validate()
        console.log(formResult)
        if (!formResult) return
        const incomeReplacementNeed = this.annualIncome * (this.percentageToReplace / 100) * (65 - this.age)
        const totalDeductions = parseInt(this.cashInvestments) + parseInt(this.lifeInsuranceInForce)
        const totalAdditions = incomeReplacementNeed +
          parseInt(this.legacyAmount) +
          parseInt(this.funeralExpenses) +
          parseInt(this.outstandingMortgage) +
          parseInt(this.outstandingDebt)

        const need = parseFloat(totalAdditions - totalDeductions).toFixed(2)
        this.insuranceNeededToday = need
        console.log(this.insuranceNeededToday)
      }
    }
  }
</script>

<style scoped>
::v-deep .v-label {
  color: #495057;
  font-size: 1.1rem
}

::v-deep .theme--light.v-input:not(.v-input--is-disabled) input {
  color: #495057;
  font-size: 1.2rem
}
</style>
